import { Injectable, resolveForwardRef } from '@angular/core';
import { AccountInfrastructureApi, InfrastructureApi } from '../shared/sdk';
import { AuthService } from './auth.service';

@Injectable()
export class CenterService {
  constructor(
    private infrastructureApi: InfrastructureApi,
    private accountInfrastructureApi: AccountInfrastructureApi,
    private authService: AuthService
  ) {}

  loadCentersWithPagination(pageNumber: number, pageSize: number) {
    const promise = new Promise((resolve, reject) => {
      if (this.authService.getCurrentAccountType() === 'Admin') {
        this.infrastructureApi
          .find({
            where: { isActive: true },
            include: [{ address: { city: { state: 'country' } } }, 'infrastructurePicture'],
            skip: pageNumber * pageSize,
            //limit: pageSize
          })
          .toPromise()
          .then((res: any) => resolve(res));
      } else if (this.authService.getCurrentAccountType() === 'Coach') {
        this.accountInfrastructureApi
          .find({
            where: {
              accountId: this.authService.getCurrentAccountId()
            },
            include: {
              infrastructure: [
                {
                  address: {
                    city: {
                      state: 'country'
                    }
                  }
                },
                'infrastructurePicture'
              ]
            }
          })
          .toPromise()
          .then((res: any) => {
            const promises = [];
            res.map((accountInfrastructure: any) => {
              promises.push(accountInfrastructure.infrastructure);
            });
            Promise.all(promises).then((response) => resolve(response));
          });
      }
    });
    return promise;
  }

  centersCount() {
    const promise = new Promise((resolve, reject) => {
      if (this.authService.getCurrentAccountType() === 'Coach') {
        const accountId = this.authService.getCurrentAccountId();
        this.accountInfrastructureApi
          .count({ accountId })
          .toPromise()
          .then((res: any) => resolve(res));
      } else if (this.authService.getCurrentAccountType() === 'Admin') {
        this.infrastructureApi
          .count({ isActive: 'true' })
          .toPromise()
          .then((res: any) => resolve(res));
      }
    });
    return promise;
  }

  loadCenters() {
    const promise = new Promise((resolve, reject) => {
      if (this.authService.getCurrentAccountType() === 'Admin') {
        this.infrastructureApi
          .find({
            where: { isActive: true },
            include: [{ address: { city: { state: 'country' } } }, 'infrastructurePicture']
          })
          .toPromise()
          .then((res: any) => resolve(res));
      } else if (this.authService.getCurrentAccountType() === 'Coach') {
        this.accountInfrastructureApi
          .find({
            where: {
              accountId: this.authService.getCurrentAccountId()
            },
            include: {
              infrastructure: [
                {
                  address: {
                    city: {
                      state: 'country'
                    }
                  }
                },
                'infrastructurePicture'
              ]
            }
          })
          .toPromise()
          .then((res: any) => {
            const promises = [];
            res.map((accountInfrastructure: any) => {
              promises.push(accountInfrastructure.infrastructure);
            });
            Promise.all(promises).then((response) => resolve(response));
          });
      }
    });
    return promise;
  }
}
