import { Injectable } from '@angular/core';
import { AccountBatchApi, BatchApi, CourseApi } from '../shared/sdk/index';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Constants } from '../class/constants';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Injectable()
export class BatchService {
  constructor(
    private authService: AuthService,
    private accountBatchApi: AccountBatchApi,
    public courseApi: CourseApi,
    private batchApi: BatchApi,
    private router: Router
  ) {}

  loadBatchesWithPagination(pageNumber: number, pageSize: number, centerId: number) {
    const promise = new Promise((resolve, reject) => {
      if (this.authService.getCurrentAccountType() === 'Admin') {
        this.batchApi
          .find({
            include: ['course', 'batchTimings'],
            where: {
              isActive: true,
              infrastructureId: centerId
            },
            skip: pageNumber * pageSize,
            limit: pageSize
          })
          .toPromise()
          .then((res: any) => resolve(res));
      } else if (this.authService.getCurrentAccountType() === 'Coach') {
        this.accountBatchApi
          .find({
            where: {
              accountId: this.authService.getCurrentAccountId()
            },
            include: {
              batch: 'course'
            }
          })
          .toPromise()
          .then((res: any) => {
            const promises: any = [];
            console.log(res);
            res.map((accountBatch: any) => {
              if (accountBatch.batch !== undefined && accountBatch.batch.infrastructureId === centerId) {
                promises.push(accountBatch.batch);
              }
            });
            return promises;
          })
          .then((promises) => {
            Promise.all(promises).then((response: any) => resolve(response));
          });
      }
    });
    return promise;
  }

  BatchesCount(centerId) {
    const promise = new Promise((resolve, reject) => {
      if (this.authService.getCurrentAccountType() === 'Coach') {
        this.accountBatchApi
          .find({
            where: {
              accountId: this.authService.getCurrentAccountId()
            },
            include: {
              batch: 'course'
            }
          })
          .toPromise()
          .then((res: any) => {
            const promises: any = [];
            res.map((accountBatch: any) => {
              if (accountBatch.batch !== undefined && accountBatch.batch.infrastructureId === centerId) {
                promises.push(accountBatch.batch);
              }
            });
            return promises;
          })
          .then((promises) => {
            Promise.all(promises).then((response: any) => resolve({ count: response.length }));
          });
      } else if (this.authService.getCurrentAccountType() === 'Admin') {
        this.batchApi
          .count({ isActive: 'true', infrastructureId: centerId })
          .toPromise()
          .then((res: any) => resolve(res));
      }
    });
    return promise;
  }

  loadCenters(centerId) {
    const promise = new Promise((resolve, reject) => {
      if (this.authService.getCurrentAccountType() === 'Admin') {
        this.batchApi
          .find({
            include: 'course',
            where: {
              isActive: true,
              infrastructureId: centerId
            }
          })
          .toPromise()
          .then((res: any) => resolve(res));
      } else if (this.authService.getCurrentAccountType() === 'Coach') {
        this.accountBatchApi
          .find({
            where: {
              accountId: this.authService.getCurrentAccountId()
            },
            include: {
              batch: 'course'
            }
          })
          .toPromise()
          .then((res: any) => {
            const promises = [];
            res.map((accountBatch: any) => {
              if (accountBatch.batch.infrastructureId === centerId) {
                promises.push(accountBatch.batch);
              }
            });
            Promise.all(promises).then((response: any) => resolve(response));
          });
      }
    });
    return promise;
  }
}
