import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  private apiUrl = `${environment.blogsUrl}`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  redirectUrl: string;
  constructor(private http: HttpClient) { }

  getBlogs(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/v1/home-blogs`);
  }

  postComment(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/v1/submit-comment`, data);
  }

  updateCar(data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/v1/cars/${data.id}`, data, this.httpOptions);
  }

  removeCar(carId: string): Observable<any> {
    console.log(carId);
    return this.http.delete(`${this.apiUrl}/api/v1/cars/${carId}`, this.httpOptions);
  }

  findCar(carId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/v1/cars/${carId}`, this.httpOptions);
  }

  getSlug(data: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/v1/details`, { params: data });
  }
}
