import { Injectable } from '@angular/core';
import { AccountBatchApi, AssessmentApi, SportApi } from '../shared/sdk/index';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Constants } from '../class/constants';

@Injectable()
export class AssessmentService {
  constructor(
    private authService: AuthService,
    private assessmentApi: AssessmentApi,
    private accountBatchApi: AccountBatchApi,
    public sportApi: SportApi,
    private router: Router
  ) {}

  fetchForms() {
    const promise = new Promise((resolve, reject) => {
      if (this.authService.getCurrentAccountType() === 'Coach') {
        this.accountBatchApi
          .find({
            where: { accountId: parseInt(this.authService.getCurrentAccountId(), 10) },
            include: { batch: 'course' }
          })
          .toPromise()
          .then((res: any) => {
            const promises = [];
            console.log(res);
            res.map((batchAcount: any) => {
              promises.push(batchAcount.batch.course.id);
            });
            Promise.all(promises)
              .then((res1: any) => {
                const obj: any = {};
                const includeArray = ['form'];
                obj.where = { isActive: true };
                obj.include = includeArray;
                obj.where.courseId = { inq: res1 };
                includeArray.push('course');
                obj.include = includeArray;
                //console.log(obj);
                return obj;
              })
              .then((obj: any) => {
                this.assessmentApi
                  .find(obj)
                  .toPromise()
                  .then((assessments: any) => resolve(assessments));
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const obj: any = {};
        //const includeArray = ['form'];
        obj.where = { isActive: true };
      //  obj.include = includeArray;
        this.assessmentApi
          .find(obj)
          .toPromise()
          .then((assessments: any) => resolve(assessments));
      }
    });
    return promise;
  }

  editForm(assessmentId: number, sportId:Number, level:any) {
    let path = '';
    path = this.authService.getCurrentAccountType() === 'Coach' ? Constants.PATHS.COACHFORMBUILDER : Constants.PATHS.FORMBUILDER;
    this.router.navigate([path], { queryParams: { assessmentId,sportId,level} });
  }

  getSports() {
    const promise = new Promise((resolve, reject) => {
      if (this.authService.getCurrentAccountType() === 'Coach') {
        this.accountBatchApi
          .find({
            where: { accountId: parseInt(this.authService.getCurrentAccountId(), 10) },
            include: { batch: 'course' }
          })
          .toPromise()
          .then((res: any) => {
            const promises = [];
            res.map((batchAcount: any) => {
              promises.push(batchAcount.batch.course);
            });
            Promise.all(promises).then((res1: any) => resolve(res1));
          });
      } else {
        this.sportApi
          .find({
            where: {
              isActive: true
            }
          })
          .toPromise()
          .then((res) => resolve(res));
      }
    });
    return promise;
  }

  navigateToViewForm(assessmentId) {
    const path = this.authService.getCurrentAccountType() === 'Coach' ? Constants.PATHS.COACHVIEWFORM : Constants.PATHS.VIEWFORM;
    this.router.navigate([path], { state: { assessmentId } });
  }
}
